/*
 * Copyright 2010-2023 JetBrains s.r.o. and Kotlin Programming Language contributors.
 * Use of this source code is governed by the Apache 2.0 license that can be found in the license/LICENSE.txt file.
 */

@file:kotlin.jvm.JvmMultifileClass
@file:kotlin.jvm.JvmName("ComparisonsKt")

package kotlin.comparisons

//
// NOTE: THIS FILE IS AUTO-GENERATED by the GenerateStandardLib.kt
// See: https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib
//

import kotlin.random.*

/**
 * Returns the greater of two values.
 * 
 * If values are equal, returns the first one.
 */
@SinceKotlin("1.1")
public expect fun <T : Comparable<T>> maxOf(a: T, b: T): T

/**
 * Returns the greater of two values.
 */
@SinceKotlin("1.1")
public expect inline fun maxOf(a: Byte, b: Byte): Byte

/**
 * Returns the greater of two values.
 */
@SinceKotlin("1.1")
public expect inline fun maxOf(a: Short, b: Short): Short

/**
 * Returns the greater of two values.
 */
@SinceKotlin("1.1")
public expect inline fun maxOf(a: Int, b: Int): Int

/**
 * Returns the greater of two values.
 */
@SinceKotlin("1.1")
public expect inline fun maxOf(a: Long, b: Long): Long

/**
 * Returns the greater of two values.
 * 
 * If either value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
public expect inline fun maxOf(a: Float, b: Float): Float

/**
 * Returns the greater of two values.
 * 
 * If either value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
public expect inline fun maxOf(a: Double, b: Double): Double

/**
 * Returns the greater of three values.
 * 
 * If there are multiple equal maximal values, returns the first of them.
 */
@SinceKotlin("1.1")
public expect fun <T : Comparable<T>> maxOf(a: T, b: T, c: T): T

/**
 * Returns the greater of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun maxOf(a: Byte, b: Byte, c: Byte): Byte

/**
 * Returns the greater of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun maxOf(a: Short, b: Short, c: Short): Short

/**
 * Returns the greater of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun maxOf(a: Int, b: Int, c: Int): Int

/**
 * Returns the greater of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun maxOf(a: Long, b: Long, c: Long): Long

/**
 * Returns the greater of three values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun maxOf(a: Float, b: Float, c: Float): Float

/**
 * Returns the greater of three values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun maxOf(a: Double, b: Double, c: Double): Double

/**
 * Returns the greater of three values according to the order specified by the given [comparator].
 * 
 * If there are multiple equal maximal values, returns the first of them.
 */
@SinceKotlin("1.1")
public fun <T> maxOf(a: T, b: T, c: T, comparator: Comparator<in T>): T {
    return maxOf(a, maxOf(b, c, comparator), comparator)
}

/**
 * Returns the greater of two values according to the order specified by the given [comparator].
 * 
 * If values are equal, returns the first one.
 */
@SinceKotlin("1.1")
public fun <T> maxOf(a: T, b: T, comparator: Comparator<in T>): T {
    return if (comparator.compare(a, b) >= 0) a else b
}

/**
 * Returns the greater of the given values.
 * 
 * If there are multiple equal maximal values, returns the first of them.
 */
@SinceKotlin("1.4")
public expect fun <T : Comparable<T>> maxOf(a: T, vararg other: T): T

/**
 * Returns the greater of the given values.
 */
@SinceKotlin("1.4")
public expect fun maxOf(a: Byte, vararg other: Byte): Byte

/**
 * Returns the greater of the given values.
 */
@SinceKotlin("1.4")
public expect fun maxOf(a: Short, vararg other: Short): Short

/**
 * Returns the greater of the given values.
 */
@SinceKotlin("1.4")
public expect fun maxOf(a: Int, vararg other: Int): Int

/**
 * Returns the greater of the given values.
 */
@SinceKotlin("1.4")
public expect fun maxOf(a: Long, vararg other: Long): Long

/**
 * Returns the greater of the given values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.4")
public expect fun maxOf(a: Float, vararg other: Float): Float

/**
 * Returns the greater of the given values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.4")
public expect fun maxOf(a: Double, vararg other: Double): Double

/**
 * Returns the greater of the given values according to the order specified by the given [comparator].
 * 
 * If there are multiple equal maximal values, returns the first of them.
 */
@SinceKotlin("1.4")
public fun <T> maxOf(a: T, vararg other: T, comparator: Comparator<in T>): T {
    var max = a
    for (e in other) if (comparator.compare(max, e) < 0) max = e
    return max
}

/**
 * Returns the smaller of two values.
 * 
 * If values are equal, returns the first one.
 */
@SinceKotlin("1.1")
public expect fun <T : Comparable<T>> minOf(a: T, b: T): T

/**
 * Returns the smaller of two values.
 */
@SinceKotlin("1.1")
public expect inline fun minOf(a: Byte, b: Byte): Byte

/**
 * Returns the smaller of two values.
 */
@SinceKotlin("1.1")
public expect inline fun minOf(a: Short, b: Short): Short

/**
 * Returns the smaller of two values.
 */
@SinceKotlin("1.1")
public expect inline fun minOf(a: Int, b: Int): Int

/**
 * Returns the smaller of two values.
 */
@SinceKotlin("1.1")
public expect inline fun minOf(a: Long, b: Long): Long

/**
 * Returns the smaller of two values.
 * 
 * If either value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
public expect inline fun minOf(a: Float, b: Float): Float

/**
 * Returns the smaller of two values.
 * 
 * If either value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
public expect inline fun minOf(a: Double, b: Double): Double

/**
 * Returns the smaller of three values.
 * 
 * If there are multiple equal minimal values, returns the first of them.
 */
@SinceKotlin("1.1")
public expect fun <T : Comparable<T>> minOf(a: T, b: T, c: T): T

/**
 * Returns the smaller of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun minOf(a: Byte, b: Byte, c: Byte): Byte

/**
 * Returns the smaller of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun minOf(a: Short, b: Short, c: Short): Short

/**
 * Returns the smaller of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun minOf(a: Int, b: Int, c: Int): Int

/**
 * Returns the smaller of three values.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun minOf(a: Long, b: Long, c: Long): Long

/**
 * Returns the smaller of three values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun minOf(a: Float, b: Float, c: Float): Float

/**
 * Returns the smaller of three values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.1")
@kotlin.internal.InlineOnly
public expect inline fun minOf(a: Double, b: Double, c: Double): Double

/**
 * Returns the smaller of three values according to the order specified by the given [comparator].
 * 
 * If there are multiple equal minimal values, returns the first of them.
 */
@SinceKotlin("1.1")
public fun <T> minOf(a: T, b: T, c: T, comparator: Comparator<in T>): T {
    return minOf(a, minOf(b, c, comparator), comparator)
}

/**
 * Returns the smaller of two values according to the order specified by the given [comparator].
 * 
 * If values are equal, returns the first one.
 */
@SinceKotlin("1.1")
public fun <T> minOf(a: T, b: T, comparator: Comparator<in T>): T {
    return if (comparator.compare(a, b) <= 0) a else b
}

/**
 * Returns the smaller of the given values.
 * 
 * If there are multiple equal minimal values, returns the first of them.
 */
@SinceKotlin("1.4")
public expect fun <T : Comparable<T>> minOf(a: T, vararg other: T): T

/**
 * Returns the smaller of the given values.
 */
@SinceKotlin("1.4")
public expect fun minOf(a: Byte, vararg other: Byte): Byte

/**
 * Returns the smaller of the given values.
 */
@SinceKotlin("1.4")
public expect fun minOf(a: Short, vararg other: Short): Short

/**
 * Returns the smaller of the given values.
 */
@SinceKotlin("1.4")
public expect fun minOf(a: Int, vararg other: Int): Int

/**
 * Returns the smaller of the given values.
 */
@SinceKotlin("1.4")
public expect fun minOf(a: Long, vararg other: Long): Long

/**
 * Returns the smaller of the given values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.4")
public expect fun minOf(a: Float, vararg other: Float): Float

/**
 * Returns the smaller of the given values.
 * 
 * If any value is `NaN`, returns `NaN`.
 */
@SinceKotlin("1.4")
public expect fun minOf(a: Double, vararg other: Double): Double

/**
 * Returns the smaller of the given values according to the order specified by the given [comparator].
 * 
 * If there are multiple equal minimal values, returns the first of them.
 */
@SinceKotlin("1.4")
public fun <T> minOf(a: T, vararg other: T, comparator: Comparator<in T>): T {
    var min = a
    for (e in other) if (comparator.compare(min, e) > 0) min = e
    return min
}

